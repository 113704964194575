#transaction-history-page {
  .p-button {
    color: #4338CA;
    background-color: #E0E7FF;
    border-color: #E0E7FF;
    outline: none;
  }

  .p-chips .p-chips-multiple-container {
    width: 100%
  }
}