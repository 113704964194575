@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  /*background-image: linear-gradient(15deg, #334046 0%, #334046 100%);*/
  /*background-image: linear-gradient(15deg, #CDD7D9 0%, #CDD7D9 100%);*/
  background-color: #efefef;



  /*background-image: linear-gradient(-225deg, #B7F8DB 0%, #50A7C2 100%);*/
  /*background-image: linear-gradient(-225deg, #CBBACC 0%, #2580B3 100%);*/
}

