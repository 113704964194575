#management-hotels-page {

  .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last  {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    border: none;
    &:hover {
      color: white;
      background-color: #3b82f6;
    }
    margin-left: 2px;
  }

  .p-paginator .p-dropdown {
    background-color: rgba(255, 255, 255, 0.3);
    margin-right: 2px;
    border-radius: 4px;
    border: none;
    color: #3b82f6;
  }

  .p-paginator .p-dropdown .p-dropdown-label {
    color: #3b82f6;
  }

  .p-dropdown .p-dropdown-trigger {
    color: #3b82f6;
  }

  .p-paginator .p-paginator-current {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    border: none;
    margin-left: 2px;

    &:hover {
      cursor: default;
    }
  }
  //
  //.p-datatable .p-datatable-tbody > tr > td {
  //  border: 1px solid rgba(255, 255, 255, .5);
  //}

  .p-datatable .p-datatable-thead > tr > th {
    border: none;
    //&:last-child {
    //  border-top-right-radius: 5px;
    //}
    //&:first-child {
    //  border-top-left-radius: 5px;
    //}



  }
}